import React from "react";
import { withPage } from "../PageContainer";
import { Link } from "gatsby";
import SEO from "../Components/seo";

const NotFoundPage = () => (
  <div style={{ margin: 20 }}>
    <SEO title="404: Not found" />
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        src="../../images/error-404.png"
        width="70%"
        style={{ maxWidth: 650, marginBottom: 30 }}
      />
      <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: 5 }}>
        很抱歉，此頁面已不存在！
      </p>
      <p style={{ fontSize: 16, fontWeight: "bold" }}>
        歡迎繼續瀏覽選購其他商品！
      </p>
    </div>
  </div>
);

export default withPage(NotFoundPage);
